import * as contentful from "contentful";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

const client = contentful.createClient({
  space: `${process.env.REACT_APP_SPACE_ID}`,
  accessToken: `${process.env.REACT_APP_ACCESS_TOKEN}`,
});

const getEntry = (path, callback) => {
  client
    .getEntries({
      content_type: "legal",
      "fields.path": path,
    })
    .then((entries) => {
      callback(null, entries.items.pop());
    })
    .catch((error) => {
      callback(error, null);
    });
};

const parseEntry = (content) => {
  return documentToHtmlString(content);
};

export { getEntry, parseEntry };
