import icon from "../assets/images/logo-icon.png";

import { useState, useEffect } from "react";

import Modal from "./Modal";

const Foot = () => {
  const [modal, setModal] = useState(null);
  useEffect(() => {}, [modal]);
  const handleModal = (event) => {
    const target = event.target.dataset.target;
    if (target === "delete") {
      setModal(null);
      document.documentElement.classList.remove("disable-scroll");
    } else {
      setModal(target);
      document.documentElement.classList.add("disable-scroll");
    }
  };

  return (
    <footer className="pt-6">
      <div className="content has-text-centered">
        <img
          className="foot-image"
          src={icon}
          alt="dataPip Icon"
          height="24"
          width="24"
        />
        <div className="foot-links">
          <button
            className="foot-link button is-white has-text-grey-light is-radiusless"
            data-target="impressum"
            onClick={handleModal}
          >
            Impressum
          </button>
          <button
            className="foot-link button is-white has-text-grey-light is-radiusless"
            data-target="datenschutz"
            onClick={handleModal}
          >
            Datenschutz
          </button>
        </div>
        <p className="has-text-grey-light is-size-7 my-2">
          &copy; {new Date().getFullYear()} dataPip.de - selbstgemacht mit
          React, Bulma und Contentful.
        </p>
      </div>
      {modal && <Modal content={modal} handler={handleModal} />}
    </footer>
  );
};

export default Foot;
