import "chart.js/auto";
import { Chart } from "react-chartjs-2";

import { getPageData, getActionData } from "../services/utils";

const InfoBarHorizontal = (props) => {
  let chartData;
  if (props.type === "page") chartData = getPageData(props.data);
  if (props.type === "action") chartData = getActionData(props.data);

  const data = {
    labels: chartData.labels.slice(0, 3),
    datasets: [
      {
        backgroundColor: ["#48c78e", "#48c78ec0", "#48c78e90"],
        borderWidth: 0,
        data: chartData.values.slice(0, 3),
      },
    ],
  };

  const options = {
    reponsive: true,
    indexAxis: "y",
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: false,
        },
        ticks: {
          precision: 0,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          precision: 0,
        },
      },
    },
  };

  return (
    <div className="tile is-parent is-6">
      <article className="tile is-child box">
        <p className="subtitle">Top 3 {props.type}s</p>
        <hr />
        <Chart
          style={{ maxHeight: "200px", maxWidth: "100%" }}
          type="bar"
          data={data}
          options={options}
        />
      </article>
    </div>
  );
};

export default InfoBarHorizontal;
