import "chart.js/auto";
import { Chart } from "react-chartjs-2";

import {
  getReferrerData,
  getBrowserData,
  getPlatformData,
} from "../services/utils";

const InfoDoughnut = (props) => {
  let chartData;
  if (props.type === "referrer") chartData = getReferrerData(props.data);
  if (props.type === "browser") chartData = getBrowserData(props.data);
  if (props.type === "platform") chartData = getPlatformData(props.data);

  const data = {
    labels: chartData.labels.slice(0, 5),
    datasets: [
      {
        backgroundColor: [
          "#48c78e",
          "#48c78ec0",
          "#48c78e90",
          "#48c78e50",
          "#48c78e10",
        ],
        data: chartData.values.slice(0, 5),
      },
    ],
  };

  const options = {
    reponsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  return (
    <div className="tile is-parent is-4">
      <article className="tile is-child box">
        <p className="subtitle">Top 5 {props.type}</p>
        <hr />
        <Chart
          type="doughnut"
          style={{ maxHeight: "300px", maxWidth: "100%" }}
          data={data}
          options={options}
        />
      </article>
    </div>
  );
};

export default InfoDoughnut;
