const InfoNote = () => {
  if (sessionStorage.getItem("notified")) return null;

  return (
    <div className="notification is-warning is-light">
      <button className="delete" onClick={handleDelete}></button>
      Die hier gezeigten Daten stammen von Besuchern und Besucherinnen der
      Webseite dataPip.de und deren Sub-Domains (z.B. encode.dataPip.de). Die
      gezeigten Information wurden mittels selbstgebautem Tracking erhoben.
      Dabei wurden weder Cookies verwendet (oder ähnliche Speichermöglichkeiten)
      noch IP-Adressen gespeichert.
    </div>
  );
};

export default InfoNote;

const handleDelete = (event) => {
  event.target.parentNode.remove();
  sessionStorage.setItem("notified", true);
};
