import { useState } from "react";

const InfoHead = (props) => {
  const [period, setPeriod] = useState(props.period);

  return (
    <section className="info-tiles">
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <article className="tile is-child has-background-light">
            <div className="field has-addons">
              <div className="control">
                <div className="select">
                  <select
                    name="period"
                    value={period}
                    onChange={(e) => setPeriod(e.target.value)}
                  >
                    <option value="today">Heute</option>
                    <option value="7days">Letzten 7 Tage</option>
                  </select>
                </div>
              </div>
              <div className="control">
                <button
                  type="submit"
                  className="button is-black"
                  onClick={() => props.updatePeriod(period)}
                >
                  Anzeigen
                </button>
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default InfoHead;
