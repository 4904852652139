import logo from "../assets/images/logo.png";

const Head = () => {
  return (
    <nav className="navbar is-menu is-light">
      <div className="navbar-brand">
        <a className="navbar-item" href="https://www.datapip.de">
          <img src={logo} alt="Logo" />
        </a>
      </div>
    </nav>
  );
};

export default Head;
