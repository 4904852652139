const Load = () => {
  return (
    <div className="hero">
      <div className="hero-body is-justify-content-center">
        <button className="button is-large is-loading has-background-light">
          Loading
        </button>
      </div>
    </div>
  );
};

export default Load;
