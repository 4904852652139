import { getDate } from "./utils";

const Reporting = {
  apiDomain: "https://api.datapip.de",
  apiVersion: "v1",
  apiPath: "r",
  apiParams: "",
  _createDates: function (period) {
    if (period === "today") return { start: getDate(0) };
    if (period === "7days") return { start: getDate(7), end: getDate(1) };
  },
  _createParams: function (object) {
    const result = [];
    for (let key in object) {
      result.push(`${key}=${object[key]}`);
    }
    const query = result.join("&");
    return `?${query}`;
  },
  _createRequest: async function () {
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    const response = await fetch(
      `${this.apiDomain}/${this.apiVersion}/${this.apiPath}${this.apiParams}`,
      options
    );
    const parsed = await response.json();
    return parsed;
  },
  getData: function (period) {
    const dates = this._createDates(period);
    this.apiParams = this._createParams(dates);
    return this._createRequest();
  },
};

// const data = [
//   {
//     timestamp_clientid: "1647597369098_802198518.8167496283",
//     date: "2022-03-18",
//     data: {
//       event: "view",
//       client: {
//         hitDepth: 1,
//         userAgent:
//           "Mozilla/5.0 (iPhone; CPU iPhone OS 15_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Mobile/15E148 Safari/604.1",
//         clientId: "802198518.8167496283",
//         clientLanguage: "de-DE",
//         clientResolution: "414x736",
//         timestamp: 1647597369098,
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/",
//         pageCategory: "",
//         pageName: "home",
//         referringURL: "",
//       },
//     },
//   },
//   {
//     timestamp_clientid: "1647597377826_802198518.8167496283",
//     date: "2022-03-18",
//     data: {
//       event: "view",
//       client: {
//         hitDepth: 2,
//         userAgent:
//           "Mozilla/5.0 (iPhone; CPU iPhone OS 15_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Mobile/15E148 Safari/604.1",
//         clientId: "802198518.8167496283",
//         clientLanguage: "de-DE",
//         clientResolution: "414x736",
//         timestamp: 1647597377826,
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/#projects",
//         pageCategory: "",
//         pageName: "projects",
//         referringURL: "",
//       },
//     },
//   },
//   {
//     timestamp_clientid: "1647597383274_802198518.8167496283",
//     date: "2022-03-18",
//     data: {
//       event: "view",
//       client: {
//         hitDepth: 3,
//         userAgent:
//           "Mozilla/5.0 (iPhone; CPU iPhone OS 15_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Mobile/15E148 Safari/604.1",
//         clientId: "802198518.8167496283",
//         clientLanguage: "de-DE",
//         clientResolution: "414x736",
//         timestamp: 1647597383274,
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/#about",
//         pageCategory: "",
//         pageName: "about",
//         referringURL: "",
//       },
//     },
//   },
//   {
//     timestamp_clientid: "1647597384173_802198518.8167496283",
//     date: "2022-03-18",
//     data: {
//       event: "view",
//       client: {
//         hitDepth: 4,
//         userAgent:
//           "Mozilla/5.0 (iPhone; CPU iPhone OS 15_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Mobile/15E148 Safari/604.1",
//         clientId: "802198518.8167496283",
//         clientLanguage: "de-DE",
//         clientResolution: "414x736",
//         timestamp: 1647597384173,
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/#contact",
//         pageCategory: "",
//         pageName: "contact",
//         referringURL: "",
//       },
//     },
//   },
//   {
//     timestamp_clientid: "1647597415389_802198518.8167496283",
//     date: "2022-03-18",
//     data: {
//       client: {
//         hitDepth: 5,
//         userAgent:
//           "Mozilla/5.0 (iPhone; CPU iPhone OS 15_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Mobile/15E148 Safari/604.1",
//         clientId: "802198518.8167496283",
//         clientLanguage: "de-DE",
//         clientResolution: "414x736",
//         timestamp: 1647597415389,
//       },
//       action: {
//         actionType: "button",
//         actionEffect: "exit",
//         actionName: "mail",
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/#contact",
//         pageCategory: "",
//         pageName: "contact",
//         referringURL: "",
//       },
//       event: "action",
//     },
//   },
//   {
//     timestamp_clientid: "1647597465753_802198518.8167496283",
//     date: "2022-03-18",
//     data: {
//       client: {
//         hitDepth: 6,
//         userAgent:
//           "Mozilla/5.0 (iPhone; CPU iPhone OS 15_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Mobile/15E148 Safari/604.1",
//         clientId: "802198518.8167496283",
//         clientLanguage: "de-DE",
//         clientResolution: "414x736",
//         timestamp: 1647597465753,
//       },
//       action: {
//         actionType: "button",
//         actionEffect: "exit",
//         actionName: "mail",
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/#contact",
//         pageCategory: "",
//         pageName: "contact",
//         referringURL: "",
//       },
//       event: "action",
//     },
//   },
//   {
//     timestamp_clientid: "1647610246664_4919700160.2169637556",
//     date: "2022-03-18",
//     data: {
//       event: "view",
//       client: {
//         hitDepth: 1,
//         userAgent:
//           "Mozilla/5.0 (iPhone; CPU iPhone OS 15_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Mobile/15E148 Safari/604.1",
//         clientId: "4919700160.2169637556",
//         clientLanguage: "de-DE",
//         clientResolution: "414x736",
//         timestamp: 1647610246664,
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/",
//         pageCategory: "",
//         pageName: "home",
//         referringURL: "https://www.datapip.de/",
//       },
//     },
//   },
//   {
//     timestamp_clientid: "1647610258005_4919700160.2169637556",
//     date: "2022-03-18",
//     data: {
//       event: "view",
//       client: {
//         hitDepth: 2,
//         userAgent:
//           "Mozilla/5.0 (iPhone; CPU iPhone OS 15_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Mobile/15E148 Safari/604.1",
//         clientId: "4919700160.2169637556",
//         clientLanguage: "de-DE",
//         clientResolution: "414x736",
//         timestamp: 1647610258005,
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/#projects",
//         pageCategory: "",
//         pageName: "projects",
//         referringURL: "https://www.datapip.de/",
//       },
//     },
//   },
//   {
//     timestamp_clientid: "1647610264342_4919700160.2169637556",
//     date: "2022-03-18",
//     data: {
//       event: "view",
//       client: {
//         hitDepth: 3,
//         userAgent:
//           "Mozilla/5.0 (iPhone; CPU iPhone OS 15_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Mobile/15E148 Safari/604.1",
//         clientId: "4919700160.2169637556",
//         clientLanguage: "de-DE",
//         clientResolution: "414x736",
//         timestamp: 1647610264342,
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/#about",
//         pageCategory: "",
//         pageName: "about",
//         referringURL: "https://www.datapip.de/",
//       },
//     },
//   },
//   {
//     timestamp_clientid: "1647610265348_4919700160.2169637556",
//     date: "2022-03-18",
//     data: {
//       event: "view",
//       client: {
//         hitDepth: 4,
//         userAgent:
//           "Mozilla/5.0 (iPhone; CPU iPhone OS 15_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Mobile/15E148 Safari/604.1",
//         clientId: "4919700160.2169637556",
//         clientLanguage: "de-DE",
//         clientResolution: "414x736",
//         timestamp: 1647610265348,
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/#contact",
//         pageCategory: "",
//         pageName: "contact",
//         referringURL: "https://www.datapip.de/",
//       },
//     },
//   },
//   {
//     timestamp_clientid: "1647610270367_4919700160.2169637556",
//     date: "2022-03-18",
//     data: {
//       client: {
//         hitDepth: 5,
//         userAgent:
//           "Mozilla/5.0 (iPhone; CPU iPhone OS 15_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Mobile/15E148 Safari/604.1",
//         clientId: "4919700160.2169637556",
//         clientLanguage: "de-DE",
//         clientResolution: "414x736",
//         timestamp: 1647610270367,
//       },
//       action: {
//         actionType: "button",
//         actionEffect: "exit",
//         actionName: "xing",
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/#contact",
//         pageCategory: "",
//         pageName: "contact",
//         referringURL: "https://www.datapip.de/",
//       },
//       event: "action",
//     },
//   },
//   {
//     timestamp_clientid: "1647610394742_451659402.2225922429",
//     date: "2022-03-18",
//     data: {
//       event: "view",
//       client: {
//         hitDepth: 1,
//         userAgent:
//           "Mozilla/5.0 (iPhone; CPU iPhone OS 15_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Mobile/15E148 Safari/604.1",
//         clientId: "451659402.2225922429",
//         clientLanguage: "de-DE",
//         clientResolution: "414x736",
//         timestamp: 1647610394742,
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/",
//         pageCategory: "",
//         pageName: "home",
//         referringURL: "https://chrome.google.com/",
//       },
//     },
//   },
//   {
//     timestamp_clientid: "1647610399712_451659402.2225922429",
//     date: "2022-03-18",
//     data: {
//       event: "view",
//       client: {
//         hitDepth: 2,
//         userAgent:
//           "Mozilla/5.0 (iPhone; CPU iPhone OS 15_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Mobile/15E148 Safari/604.1",
//         clientId: "451659402.2225922429",
//         clientLanguage: "de-DE",
//         clientResolution: "414x736",
//         timestamp: 1647610399712,
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/#projects",
//         pageCategory: "",
//         pageName: "projects",
//         referringURL: "https://chrome.google.com/",
//       },
//     },
//   },
//   {
//     timestamp_clientid: "1647610400560_451659402.2225922429",
//     date: "2022-03-18",
//     data: {
//       event: "view",
//       client: {
//         hitDepth: 3,
//         userAgent:
//           "Mozilla/5.0 (iPhone; CPU iPhone OS 15_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Mobile/15E148 Safari/604.1",
//         clientId: "451659402.2225922429",
//         clientLanguage: "de-DE",
//         clientResolution: "414x736",
//         timestamp: 1647610400560,
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/#about",
//         pageCategory: "",
//         pageName: "about",
//         referringURL: "https://chrome.google.com/",
//       },
//     },
//   },
//   {
//     timestamp_clientid: "1647610400562_451659402.2225922429",
//     date: "2022-03-18",
//     data: {
//       event: "view",
//       client: {
//         hitDepth: 4,
//         userAgent:
//           "Mozilla/5.0 (iPhone; CPU iPhone OS 15_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Mobile/15E148 Safari/604.1",
//         clientId: "451659402.2225922429",
//         clientLanguage: "de-DE",
//         clientResolution: "414x736",
//         timestamp: 1647610400562,
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/#contact",
//         pageCategory: "",
//         pageName: "contact",
//         referringURL: "https://chrome.google.com/",
//       },
//     },
//   },
//   {
//     timestamp_clientid: "1647610402442_451659402.2225922429",
//     date: "2022-03-18",
//     data: {
//       client: {
//         hitDepth: 5,
//         userAgent:
//           "Mozilla/5.0 (iPhone; CPU iPhone OS 15_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Mobile/15E148 Safari/604.1",
//         clientId: "451659402.2225922429",
//         clientLanguage: "de-DE",
//         clientResolution: "414x736",
//         timestamp: 1647610402442,
//       },
//       action: {
//         actionType: "button",
//         actionEffect: "exit",
//         actionName: "linkedin",
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/#contact",
//         pageCategory: "",
//         pageName: "contact",
//         referringURL: "https://chrome.google.com/",
//       },
//       event: "action",
//     },
//   },
//   {
//     timestamp_clientid: "1647615485033_8961520347.8296170957",
//     date: "2022-03-18",
//     data: {
//       event: "view",
//       client: {
//         hitDepth: 1,
//         userAgent:
//           "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.51 Safari/537.36",
//         clientId: "8961520347.8296170957",
//         clientLanguage: "en-GB",
//         clientResolution: "5120x1440",
//         timestamp: 1647615485033,
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/",
//         pageCategory: "",
//         pageName: "home",
//         referringURL: "https://www.google.de/",
//       },
//     },
//   },
//   {
//     timestamp_clientid: "1647615502954_8961520347.8296170957",
//     date: "2022-03-18",
//     data: {
//       event: "view",
//       client: {
//         hitDepth: 2,
//         userAgent:
//           "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.51 Safari/537.36",
//         clientId: "8961520347.8296170957",
//         clientLanguage: "en-GB",
//         clientResolution: "5120x1440",
//         timestamp: 1647615502954,
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/#projects",
//         pageCategory: "",
//         pageName: "projects",
//         referringURL: "https://www.google.de/",
//       },
//     },
//   },
//   {
//     timestamp_clientid: "1647615510501_8961520347.8296170957",
//     date: "2022-03-18",
//     data: {
//       event: "view",
//       client: {
//         hitDepth: 3,
//         userAgent:
//           "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.51 Safari/537.36",
//         clientId: "8961520347.8296170957",
//         clientLanguage: "en-GB",
//         clientResolution: "5120x1440",
//         timestamp: 1647615510501,
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/#about",
//         pageCategory: "",
//         pageName: "about",
//         referringURL: "https://www.google.de/",
//       },
//     },
//   },
//   {
//     timestamp_clientid: "1647615517522_8961520347.8296170957",
//     date: "2022-03-18",
//     data: {
//       event: "view",
//       client: {
//         hitDepth: 4,
//         userAgent:
//           "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.51 Safari/537.36",
//         clientId: "8961520347.8296170957",
//         clientLanguage: "en-GB",
//         clientResolution: "5120x1440",
//         timestamp: 1647615517522,
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/#contact",
//         pageCategory: "",
//         pageName: "contact",
//         referringURL: "https://www.google.de/",
//       },
//     },
//   },
//   {
//     timestamp_clientid: "1647615542110_8568490050.5849442181",
//     date: "2022-03-18",
//     data: {
//       event: "view",
//       client: {
//         hitDepth: 1,
//         userAgent:
//           "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.51 Safari/537.36",
//         clientId: "8568490050.5849442181",
//         clientLanguage: "en-GB",
//         clientResolution: "5120x1440",
//         timestamp: 1647615542110,
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/",
//         pageCategory: "",
//         pageName: "home",
//         referringURL: "https://www.datapip.de/",
//       },
//     },
//   },
//   {
//     timestamp_clientid: "1647615549578_8568490050.5849442181",
//     date: "2022-03-18",
//     data: {
//       event: "view",
//       client: {
//         hitDepth: 2,
//         userAgent:
//           "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.51 Safari/537.36",
//         clientId: "8568490050.5849442181",
//         clientLanguage: "en-GB",
//         clientResolution: "5120x1440",
//         timestamp: 1647615549578,
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/#projects",
//         pageCategory: "",
//         pageName: "projects",
//         referringURL: "https://www.datapip.de/",
//       },
//     },
//   },
//   {
//     timestamp_clientid: "1647615549713_8568490050.5849442181",
//     date: "2022-03-18",
//     data: {
//       event: "view",
//       client: {
//         hitDepth: 3,
//         userAgent:
//           "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.51 Safari/537.36",
//         clientId: "8568490050.5849442181",
//         clientLanguage: "en-GB",
//         clientResolution: "5120x1440",
//         timestamp: 1647615549713,
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/#about",
//         pageCategory: "",
//         pageName: "about",
//         referringURL: "https://www.datapip.de/",
//       },
//     },
//   },
//   {
//     timestamp_clientid: "1647615549808_8568490050.5849442181",
//     date: "2022-03-18",
//     data: {
//       event: "view",
//       client: {
//         hitDepth: 4,
//         userAgent:
//           "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.51 Safari/537.36",
//         clientId: "8568490050.5849442181",
//         clientLanguage: "en-GB",
//         clientResolution: "5120x1440",
//         timestamp: 1647615549808,
//       },
//       page: {
//         site: "www.datapip.de",
//         destinationURL: "https://www.datapip.de/",
//         pageId: "/#contact",
//         pageCategory: "",
//         pageName: "contact",
//         referringURL: "https://www.datapip.de/",
//       },
//     },
//   },
// ];

// const items = period === "today" ? data.slice(0, 12) : data;

// return new Promise((resolve, reject) => {
//   setTimeout(() => {
//     resolve({
//       Items: items,
//     });
//   }, 1000);
// });
// };

export { Reporting };
