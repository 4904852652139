import { useEffect, useState } from "react";
import { Reporting } from "../services/reporting";

import InfoHead from "./InfoHead";
import InfoNumber from "./InfoNumber";
import InfoBarHorizontal from "./InfoBarHorizontal";
import InfoDoughnut from "./InfoDoughnut";
import InfoNote from "./InfoNote";
import Load from "./Load";
import TilesWrapper from "./TilesWrapper";
import InfoBarVertical from "./InfoBarVertical";

import Tracking from "../services/tracking";

const Info = () => {
  const [period, setPeriod] = useState("today");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const updatePeriod = (period) => {
    setPeriod(period);
  };

  useEffect(() => {
    const fetch = async () => {
      const fetched = await Reporting.getData(period);
      setData(fetched.Items);
      setLoading(false);
    };
    setData(null);
    setLoading(true);
    fetch();
    Tracking.sendView(`info:${period}`, "/", "");
  }, [period]);

  return (
    <div
      id="report"
      className="hero is-hero is-light is-fullheight-with-navbar"
    >
      <div className="hero-head">
        <div className="container has-text-centered">
          <h1 className="title is-3 is-size-4-touch">Analytics-Daten</h1>
          <h2 className="subtitle is-5 is-size-6-touch is-italic">
            Reporting für die dataPip.de Seiten
          </h2>
        </div>
      </div>
      <div className="hero-body">
        <div className="container">
          <InfoNote></InfoNote>
          {loading && <Load></Load>}
          {data && (
            <InfoHead period={period} updatePeriod={updatePeriod}></InfoHead>
          )}
          {data && (
            <TilesWrapper>
              <InfoNumber data={data} />
            </TilesWrapper>
          )}
          {data && (
            <TilesWrapper>
              <InfoBarHorizontal type="page" data={data} />
              <InfoBarHorizontal type="action" data={data} />
            </TilesWrapper>
          )}
          {data && (
            <TilesWrapper>
              <InfoDoughnut type="referrer" data={data} />
              <InfoDoughnut type="platform" data={data} />
              <InfoDoughnut type="browser" data={data} />
            </TilesWrapper>
          )}
          {data && (
            <TilesWrapper>
              <InfoBarVertical data={data} />
            </TilesWrapper>
          )}
        </div>
      </div>
    </div>
  );
};

export default Info;
