import "chart.js/auto";
import { Chart } from "react-chartjs-2";

import { getHourData } from "../services/utils";

const InfoBarVertical = (props) => {
  let chartData = getHourData(props.data);

  const data = {
    labels: chartData.labels,
    datasets: [
      {
        backgroundColor: ["#48c78e", "#48c78ec0", "#48c78e90"],
        borderWidth: 0,
        data: chartData.values,
      },
    ],
  };

  const options = {
    reponsive: true,
    indexAxis: "x",
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: false,
        },
        ticks: {
          precision: 0,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          precision: 0,
        },
      },
    },
  };

  return (
    <div className="tile is-parent is-12">
      <article className="tile is-child box">
        <p className="subtitle">Events by hour</p>
        <hr />
        <Chart
          type="bar"
          style={{ maxHeight: "300px", maxWidth: "100%" }}
          data={data}
          options={options}
        />
      </article>
    </div>
  );
};

export default InfoBarVertical;
