const InfoNumber = ({ data }) => {
  const infoNumbers = [];

  const sessions = [];
  data.forEach((item) => {
    const id = item.data.client.clientId;
    if (sessions.includes(id)) return;
    sessions.push(id);
  });

  const pages = data.filter((item) => {
    const event = item.data.event;
    if (event === "view") return true;
    return false;
  });

  const actions = data.filter((item) => {
    const event = item.data.event;
    if (event !== "view") return true;
    return false;
  });

  infoNumbers.push({
    name: "Sessions",
    value: sessions.length,
  });

  infoNumbers.push({
    name: "Page views",
    value: pages.length,
  });

  infoNumbers.push({
    name: "Click actions",
    value: actions.length,
  });

  return (
    <>
      {infoNumbers.map((number) => {
        return (
          <div key={number.name} className="tile is-parent">
            <article className="tile is-child box">
              <p className="subtitle">{number.name}</p>
              <hr />
              <p className="title is-1">{number.value}</p>
            </article>
          </div>
        );
      })}
    </>
  );
};

export default InfoNumber;
