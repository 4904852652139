const _getUniquePerSession = (data, element) => {
  const path = element.split(".");
  path.shift();

  const result = {};
  data.forEach((item) => {
    const element = path.reduce((previous, current) => {
      if (!previous) return undefined;
      return previous[current];
    }, item);

    const id = item.data.client.clientId;
    if (result[id]) return;
    result[id] = element;
  });
  return result;
};

const _countPages = (data) => {
  const result = {};
  data.forEach((item) => {
    const event = item.data.event;
    if (event !== "view") return;
    result[item.data.page.pageName] = result[item.data.page.pageName] + 1 || 1;
  });
  return result;
};

const _countActions = (data) => {
  const result = {};
  data.forEach((item) => {
    const event = item.data.event;
    if (event !== "action") return;
    const name = `${item.data.action.actionName} - ${item.data.action.actionEffect}`;
    result[name] = result[name] + 1 || 1;
  });
  return result;
};

const _countHours = (data) => {
  const hours = {};
  for (let h = 0; h < 24; h++) {
    hours[h] = 0;
  }
  data.forEach((item) => {
    const timestamp = item.data.client.timestamp;
    const hour = new Date(timestamp).getHours();
    hours[hour]++;
  });
  const result = [];
  for (let hour in hours) {
    result.push([hour, hours[hour]]);
  }
  return result;
};

const _countReferrer = (data) => {
  const referrer = _getUniquePerSession(data, "item.data.page.referringURL");

  const result = {};
  for (let index in referrer) {
    let currentReferrer = referrer[index].split("/").slice(2, 3).pop() || "";
    if (currentReferrer === "") currentReferrer = "direct";
    if (currentReferrer.indexOf("datapip") !== -1) currentReferrer = "intern";
    result[currentReferrer] = ++result[currentReferrer] || 1;
  }
  return result;
};

const _countBrowser = (data) => {
  const browser = _getUniquePerSession(data, "item.data.client.userAgent");

  const result = {};
  for (let index in browser) {
    let currentBrowser = browser[index];

    if (currentBrowser.indexOf("OPR") !== -1) {
      currentBrowser = "Opera";
    } else if (currentBrowser.indexOf("Edg") !== -1) {
      currentBrowser = "Edge";
    } else if (currentBrowser.indexOf("Chrome") !== -1) {
      currentBrowser = "Chrome";
    } else if (currentBrowser.indexOf("Safari") !== -1) {
      currentBrowser = "Safari";
    } else if (currentBrowser.indexOf("Firefox") !== -1) {
      currentBrowser = "Firefox";
    } else {
      currentBrowser = "Other";
    }

    result[currentBrowser] = ++result[currentBrowser] || 1;
  }
  return result;
};

const _countPlatform = (data) => {
  const platform = _getUniquePerSession(data, "item.data.client.userAgent");

  const result = {};
  for (let index in platform) {
    let currentBrowser = platform[index];
    if (currentBrowser.indexOf("Win") !== -1) {
      currentBrowser = "Windows OS";
    } else if (currentBrowser.indexOf("like Mac") !== -1) {
      currentBrowser = "iOS";
    } else if (currentBrowser.indexOf("Mac") !== -1) {
      currentBrowser = "Macintosh";
    } else if (currentBrowser.indexOf("Linux") !== -1) {
      currentBrowser = "Linux OS";
    } else if (currentBrowser.indexOf("Android") !== -1) {
      currentBrowser = "Android OS";
    } else {
      currentBrowser = "Other";
    }

    result[currentBrowser] = ++result[currentBrowser] || 1;
  }
  return result;
};

const _sortObject = (object) => {
  const result = [];
  for (let item in object) {
    result.push([item, object[item]]);
  }
  result.sort((a, b) => {
    return b[1] - a[1];
  });
  return result;
};

const _splitList = (list, fill) => {
  const result = {
    labels: [],
    values: [],
  };
  list.forEach((item) => {
    const [label, value] = item;
    result.labels.push(label);
    result.values.push(value);
  });
  if (fill) {
    while (result.values.length < fill) {
      result.labels.push("");
      result.values.push(0);
    }
  }
  return result;
};

const getPageData = (data) => {
  const pageCount = _countPages(data);
  const pageSorted = _sortObject(pageCount);
  const pageData = _splitList(pageSorted, 3);
  return pageData;
};

const getActionData = (data) => {
  const actionCount = _countActions(data);
  const actionSorted = _sortObject(actionCount);
  const actionData = _splitList(actionSorted, 3);
  return actionData;
};

const getHourData = (data) => {
  const hourCount = _countHours(data);
  const hourData = _splitList(hourCount, 0);
  return hourData;
};

const getReferrerData = (data) => {
  const referrerCount = _countReferrer(data);
  const referrerSorted = _sortObject(referrerCount);
  const referrerData = _splitList(referrerSorted, 0);
  return referrerData;
};

const getBrowserData = (data) => {
  const browserCount = _countBrowser(data);
  const browserSorted = _sortObject(browserCount);
  const browserData = _splitList(browserSorted, 0);
  return browserData;
};

const getPlatformData = (data) => {
  const platformCount = _countPlatform(data);
  const platformSorted = _sortObject(platformCount);
  const platformData = _splitList(platformSorted, 0);
  return platformData;
};

const getDate = (delta) => {
  const datetime = new Date();
  datetime.setDate(datetime.getDate() - delta);
  const date = datetime.toISOString().slice(0, 10);
  return date;
};

export {
  getDate,
  getPageData,
  getActionData,
  getHourData,
  getReferrerData,
  getBrowserData,
  getPlatformData,
};
